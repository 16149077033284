import React from "react";
import strategyImg from "../img/Strategy.jpg";
import { FaHeartbeat } from "react-icons/fa";
import { FaCapsules } from "react-icons/fa";
import { FaMicrochip } from "react-icons/fa";
import { FaMobileAlt } from "react-icons/fa";
import { FaLaptopMedical } from "react-icons/fa";
import { FaChartLine } from "react-icons/fa";
import { FaUser } from "react-icons/fa";

function About() {
  return (
    <div id="about_section">
      {/* Container (About Section) */}

      <div className="w3-content w3-container w3-padding-64" id="about">
        <h3 className="w3-center">ABOUT US</h3>

        <p>
          Ambient Design is a creative team with a passion for Connected Health,
          and a wealth of experience in Patient-centric Drug Delivery. 
          <br></br>
          Ambient was founded on Agile Innovation principles, targetting
          early-phase MVPs, and Prototypes, enabling Rapid iterations to
          key decision points. - Developing better products, faster.
          <em>- ambient, we're imagineering connected health</em>
        </p>

        <div className="w3-row">
          <div className="w3-col m6 w3-center w3-padding-large w3-display-container">
            <p>
              <b>
                <FaUser 
                  style={{marginRight: "10px"}}
                />Designing Together
              </b>
            </p>
            <br></br>
            <img
              src={strategyImg}
             className="w3-round w3-image w3-opacity w3-hover-opacity-off "
              alt="© WavebreakMediaMicro – stock.adobe.com"
              width="500"
              height="333"
            ></img>
            <div id="stratPic" className="w3-display-bottomleft">
              © WavebreakMediaMicro – stock.adobe.com
            </div>
          </div>

          {/* Hide this text on small devices */}

          <div className="w3-col m6 w3-hide-small w3-padding-large" id="subText">
            <p>
              As 'Digital Natives' in healthcare, we bring together a broad
              experience in Drug delivery and Device Hardware; with Custom
              Micro-electronics, Sensors, Apps and Cloud Software to deliver
              class-leading Smart Products.
              <br></br>
              Consistant throughout our projects is Ambient's innovative thinking and creative problem solving. 
              We seek to proactively understand customer needs, and provide elegant solutions. <br></br>
              Ambient work with you to understand the big picture. We collaborate with our clients to develop tangible, future-facing
              strategies.
            </p>
          </div>
        </div>

        <p className="w3-large w3-center w3-padding-16">
          {" "}
          Some of our Key Capabilities:
        </p>

        <p className="w3-wide">
        <FaHeartbeat />&ensp;&ensp;Connected Health
        </p>

        <div className="w3-light-grey">
          <hr className="w3-border-grey" style={{ margin: "auto;width:100%" }}></hr>
        </div>

        <p className="w3-wide">
          <FaCapsules />&ensp;&ensp;Smart Drug Delivery
        </p>

        <div className="w3-light-grey">
          <hr className="w3-border-grey" style={{ margin: "auto;width:100%" }}></hr>
        </div>

        <p className="w3-wide">
          <FaMicrochip />&ensp;&ensp;Embedded Electronics,
          Sensors and Displays
        </p>

        <div className="w3-light-grey">
          <hr className="w3-border-grey" style={{ margin: "auto;width:100%" }}></hr>
        </div>

        <p className="w3-wide">
          <FaMobileAlt />&ensp;&ensp;Mobile Apps and Wearables
        </p>

        <div className="w3-light-grey">
          <hr className="w3-border-grey" style={{ margin: "auto;width:100%" }}></hr>
        </div>

        <p className="w3-wide">
          <FaLaptopMedical />&ensp;&ensp;Web, Cloud and
          Advanced Analytics
        </p>

        <div className="w3-light-grey">
          <hr className="w3-border-grey" style={{ margin: "auto;width:100%" }}></hr>
        </div>

        <p className="w3-wide">
          <FaChartLine />&ensp;&ensp;Digital Strategy
        </p>

        <div className="w3-light-grey">
          <hr className="w3-border-grey" style={{ margin: "auto;width:100%" }}></hr>
        </div>
      </div>
    </div>
  );
}

export default About;
