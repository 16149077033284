import React from "react";
import { FaWindowClose } from "react-icons/fa";
import { FaMapMarker } from "react-icons/fa";
import { FaPhone } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";
import { FaPaperPlane } from "react-icons/fa";
import { FaArrowUp } from "react-icons/fa";
import { FaCoffee } from "react-icons/fa";
import helpers from '../Helper.js';

let ambientEmailAddress = "andrew@ambient-design.net";

function Contact() {
  return (
    <div id="contact_section">
      <div className="w3-center w3-section">
        
      
      <a href="#contact" className="w3-button w3-light-grey w3-round-large"
        >
        <FaEnvelope 
         className="w3-margin-right w3-grey"
        />CONTACT US TO FIND OUT MORE
      </a>

        <br></br>
        <br></br>
      <a href="#home" className="w3-button w3-light-grey w3-round-large">
          <FaArrowUp 
           className="w3-margin-right"
          />To the top
      </a>

        <br></br>
      </div>

      {/* Modal for full size images on click AMB need Icon 
        Does fa-windows-close work ?
        */}

      <div
        id="modal01"
       className="w3-modal w3-white"
        onClick={(e)=>{helpers.closeWindow(e)}}
      >
        <span
         className="w3-button w3-large w3-white w3-display-topright"
          title="Close Modal Image"
        >
          <FaWindowClose />
        </span>
        <div className="w3-modal-content w3-animate-zoom w3-center w3-transparent w3-padding-32">
          <img id="img01" className="w3-image" alt=""></img>
          <p id="caption" className="w3-opacity w3-large"></p>
          <p>images © 2021 Ambient, or as marked.</p>
        </div>
      </div>

      {/* Third Parallax Image with capabilities Text */}
      <div className="bgimg-3 w3-display-container w3-opacity-min" id="contact">
        <div className="w3-display-middle">
          <div>
            <span className="w3-xxlarge w3-text-white w3-wide">CONTACT</span>
          </div>
        </div>
      </div>

      {/* Container (Contact Section) */}
      <div className="w3-content w3-container w3-padding-32">
        <h3 className="w3-center">HOW TO FIND US</h3>

        <div className="w3-row w3-padding-32 w3-section">
          <div className="w3-col m4 w3-container">

          <object 
            data="https://www.openstreetmap.org/export/embed.html?bbox=7.571350848811563%2C47.568649418920444%2C7.583023822444376%2C47.5780735064882&amp;layer=mapnik&amp;marker=47.573361674701474%2C7.577187335627968" 
            width="100%"
            height="340"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            style={{ border: "1px solid grey" }}
          >
          </object>
            <br></br>
            <small>
              <a 
                href="https://www.openstreetmap.org/?mlat=47.5734&amp;mlon=7.5772#map=16/47.5734/7.5772&amp;layers=N"
                target="_blank"
                rel="noopener noreferrer"
              >
                View Larger Map
              </a>
            </small>
          </div>

          <div className="w3-col m8 w3-panel">
            <div className="w3-large">
              <FaMapMarker className="w3-hover-text-blue w3-large w3-margin-right" />{" "}
              Switzerland Innovation Park, Basel
              <br></br>
              <FaPhone className="w3-hover-text-blue w3-large w3-margin-right" />{" "}
              Phone: +41 79 723 87 89
              <br></br>
              <FaEnvelope className="w3-hover-text-blue w3-large w3-margin-right" />{" "}
              Email: <a href={"mailto:" + ambientEmailAddress}>{ambientEmailAddress}</a>
      
            </div>

            <div className="w3-col m8 w3-panel">
            <p>
              Ambient are a friendly team with a truely international feel.
              Based in the Healthcare Innovation hub of Basel, Switzerland, with
              strong contacts into both Europe, and the US.{" "}
            </p>
            

            <div className="w3-large">
              <p><em>
                Come by for a cup of &nbsp;
                <FaCoffee className= "w3-small" />{" "}
                , or <a className= "w3-text-blue w3-hover-text-grey" href={"mailto:" + ambientEmailAddress}>email</a> us,{" "}
              
              <br></br>
              &nbsp;&nbsp;We'd love your feedback!</em>
              </p>
            </div>
            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
