import React from "react";
import webPic1Img from "../img/Web-Pic1.svg";
import smartDeliveryImg from "../img/Smart Delivery and Apps.png";
import wearableDiagnosticsImg from "../img/Wearable Diagnostics.png";
import microPumpsImg from "../img/Micropumps and Controlled Loop.png";
import teleHealthImg from "../img/TeleHealth Omnichannel.jpg";
import mvpEcoSystemsImg from "../img/MVP Ecosystems.png";
import strategyGraphImg from "../img/Strategy-graph.svg";
import helpers from '../Helper.js';

function Capabilities() {
  return (
    <div id="capabilities_section">
      <div className="w3-row w3-center w3-dark-grey w3-padding-16">
        <div className="w3-section">
          <span className="w3-xlarge w3-wide">Creativity Unleashed !</span>
          <br></br>
        </div>
      </div>

      {/* Second Parallax Image with capabilities Text */}

      <div className="bgimg-2 w3-display-container w3-opacity-min">
        <div className="w3-center">
          <span
            id="line3"
           className="w3-display-middle w3-xxxlarge w3-text-white w3-wide"
          >
            WHAT DO YOU SEE ?
          </span>
        </div>

        <div className="w3-center">
          <span
            id="line4"
           className="w3-display-bottommiddle w3-xxxlarge w3-text-white w3-wide"
          >
            <u>we</u> see endless possibilities
          </span>
        </div>
        <div
         className="w3-display-bottomright w3-hide-small"
          style={{ color: "rgba(135, 71, 8, 0.721)" }}
        >
          brooke-cagle-Z2RCJ-PNRAw-unsplash
        </div>
      </div>

      <div className="w3-row w3-center w3-dark-grey w3-padding-16">
        <div className="w3-section">
          <span className="w3-xlarge w3-wide">
            ...a little flavour of our work.
          </span>
          <br></br>
        </div>
      </div>

      <div className="w3-content w3-container" id="capabilities">
        <h3 className="w3-center">Some of our capabilities.</h3>
        <p className="w3-center">
        <em>Click on the images to make them bigger</em>
          <br></br>
          <a href="#contact"> Contact</a> us to find out more...
          <br></br>
          <br></br>
        </p>

        {/* Responsive Grid. Four columns on tablets, laptops and desktops. Will stack on mobile devices/small screens (100% width) 
        AMB Images Transparent or White background 
        - Need the correct Aspect Ratio to properly display text on widescreen
        */}

        <div className="w3-row-padding w3-center">
          <div className="w3-col m3">
            <img
              src={webPic1Img}
              style={{width:"100%"}}
              onClick={(e)=>{helpers.openWindow(e)}}
             className="w3-hover-opacity"
              alt="Ambient develop a wide range of Novel Drug Delivery systems and Packaging. Including Parenteral, Transdermal, Nasal, Inhaled, Ophthalmic, Modified release and Smart-Pills">
            </img>
          </div>

          <div className="w3-col m3">
            <img
              src={smartDeliveryImg}
              style={{width:"100%"}}
              onClick={(e)=>{helpers.openWindow(e)}}
             className="w3-hover-opacity"
              alt="We develop Customer-centric Delivery Devices, Diagnostics and Mobile Apps. Come and talk to us about our MVP Platforms, designed to get you to answers... fast !"
            ></img>
          </div>

          <div className="w3-col m3">
            <img
              src={wearableDiagnosticsImg}
              style={{width:"100%"}}
              onClick={(e)=>{helpers.openWindow(e)}}
             className="w3-hover-opacity"
              alt="We have expertese in Custom Wearables, Digital Biomarkers and advanced Diagnostic Algorithms"
            ></img>
          </div>

          <div className="w3-col m3">
            <img
              src={microPumpsImg}
              style={{width:"100%"}}
              onClick={(e)=>{helpers.openWindow(e)}}
             className="w3-hover-opacity"
              alt="Come and talk to us about novel mechanical and electromechanical systems, such as Smart AutoInjectors, On-Body Injectors, Micro-Pumps, Controlled-Loop Delivery and much more"
            ></img>
          </div>
        </div>

        <div className="w3-row-padding w3-center w3-section">
          <div className="w3-col m3">
            <img
              src={teleHealthImg}
              style={{width:"100%"}}
              onClick={(e)=>{helpers.openWindow(e)}}
             className="w3-hover-opacity"
              alt="We develop Smart Product Systems from Telehealth and Omnichannel Marketing, through to complete Digital Medicines."
            ></img>
          </div>

          <div className="w3-col m3">
            <img
              src={mvpEcoSystemsImg}
              style={{width:"100%"}}
              onClick={(e)=>{helpers.openWindow(e)}}
             className="w3-hover-opacity"
              alt="We bring everything together with MVP Cloud Ecosystems and Advanced Analytics. However, Technology is only part of the story... We develop highly customer-centric solutions, to meet the needs of stakeholders, including Patients and HCPs"
            ></img>
          </div>

          <div className="w3-col m3">
            <img
              src={strategyGraphImg}
              style={{width:"100%"}}
              onClick={(e)=>{helpers.openWindow(e)}}
             className="w3-hover-opacity"
              alt="What does the future hold ? - Let's explore together. Ambient works with companies to Futurescape and develop their Digital and Drug Delivery Strategies. Helping you secure the long term health of your business"
            ></img>
          </div>
        </div>

        <p className="w3-center">
          <em>Click on the images to make them bigger</em>
        </p>
      </div>
    </div>
  );
}

export default Capabilities;
