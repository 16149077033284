// Modal Image Gallery

const helpers = {
    closeWindow: function(element) {
        document.getElementById("modal01").style.display = "none";
    },

    openWindow: function(element) {
        document.getElementById("img01").src = element.nativeEvent.target.src;
        document.getElementById("modal01").style.display = "block";
        var captionText = document.getElementById("caption");
        captionText.innerHTML = element.nativeEvent.target.alt;
      },
      
      myFunction: function () {
        var navbar = document.getElementById("myNavbar");
        if (
          document.body.scrollTop > 100 ||
          document.documentElement.scrollTop > 100
        ) {
          navbar.className = "w3-bar w3-card w3-animate-top w3-white";
        } else {
          navbar.className = navbar.className.replace(
            " w3-card w3-animate-top w3-white",
            ""
          );
        }
      },
      
      // Used to toggle the menu on small screens when clicking on the menu button
      toggleFunction: function(mode=0) {
        //mode = 0 default to show menu it not shown
        //mode = 1 pass if you want to close only, for mobile situation
        var x = document.getElementById("navDemo");
        if (x.className.indexOf("w3-show") === -1 && mode === 0) {
          x.className += " w3-show";
        } else {
          x.className = x.className.replace(" w3-show", "");
        }
      }
}

  // Change style of navbar on scroll
  window.onscroll = function () {
    helpers.myFunction();
  };

  export default helpers;
